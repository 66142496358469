<template>

  <div class="list-tabledata">

    

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
             
                 
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
            <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
             
            </div>
          </b-col>
          
        </b-row>

      </div>

      <b-table
      data-testid="diploma-seo-table"
        ref="refCourseListTable"
        class="position-relative"
       
        responsive
            :fields="tableColumns"
        primary-key="id"
        
         :items="allItem"
    striped
    
        show-empty
        empty-text="No matching records found"
    
      >

       

        <!-- Column: Status -->
        
        <!-- Column: Actions -->
            <template #cell(actions)="data">
            <a
            v-if="$can('delete', 'seo_course')"
            role="button"
             @click="DeleteSeoCotnent(data.item.id)">
           
              <feather-icon icon="TrashIcon"
              :data-testid="`delete-item-${data.item.id}`"

              /></a>
        
        </template>
     
     

      </b-table>
       <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
            Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BDropdownItemButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, computed,watch, reactive } from '@vue/composition-api'
import Vue from "vue";
// import useCousresList from './useCousresList'
// import userStoreModule from '../userStoreModule'


export default {
  components: {
  
    // useCousresList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
BDropdownItemButton,
    vSelect,
  },
  setup() {
    const coursesList=ref([])
    const meta=reactive({})
    const perPageOptions = [ 25, 50, 100,200]
     const perPage = ref(10)
       const refCourseListTable=ref(null)
  const totalCategories = ref(0)
  const searchQuery = ref('')
  const currentPage = ref(1)
  const allItem=ref([])
  const refetchData = () => {
  
    refCourseListTable.value.refresh()
  }
   watch([currentPage, perPage,searchQuery], () => {
 
    GetAllCourses()

  })
    const GetAllCourses = (ctx, callback) => {
      store.dispatch('longSeo/getAllCoursesSEOOnline', {
        'filter[search]':searchQuery.value,
          
      
        per_page: perPage.value,
        page: currentPage.value,
      
      })
        .then(response => {
         //   console.log("response",response.data)
          // coursesList.value=response.data.data
            // const { data } = response.data
            allItem.value = response.data.data
 totalCategories.value=response.data.meta.total
            //   console.log("totalCategories",totalCategories)
        // callback(data)
        
       
          
        })
    }
    GetAllCourses()
     
   const dataMeta = computed(() => {
    const localItemsCount = refCourseListTable.value ? refCourseListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    }
  })
    // GetAllCourses()
        const DeleteSeoCotnent=(id)=>{
        Vue.swal({
        icon: "warning",
        title: "Are You Sure?" ,
        confirmButtonText: "YES",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
       
        },
      }).then((result) => {
        if (result.value) {
           store.dispatch('longSeo/DeleteClassicCourse',id)
        .then(response => {
          GetAllCourses()
           Vue.swal({
                       title: "Diplomas Deleted ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        })
        }})


        }
const tableColumns=[
    
          { key: 'id',lanel:"ID"},
    { key:"long_course.name",label: 'Course '},
       { label: 'Title',key:'title'},
        {
      key: 'description',
      label: 'Descripation',
  
     
    },
    { key: 'keywords', label: 'Key Word' },
    { key: 'actions' },
    
]

    return {
        tableColumns,
        coursesList,
        DeleteSeoCotnent,
        meta,  perPageOptions,
          totalCategories,
          currentPage,
          perPage,
          dataMeta,
          refCourseListTable,
          refetchData,
          GetAllCourses,
          searchQuery,
          allItem


    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
